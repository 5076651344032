import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { externalLinks } from "../../../pages-data/_V2/common/links";

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg";

import mediaContent from "./mediaContent.module.scss";
import MobileAppBackdropIcon from "../../../assets/images/mobileFeatures/MobileAppBackdropIcon";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import WebsiteBackdropIcon from "../../../assets/images/mobileFeatures/WebsiteBackdropIcon";
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: "Принимай заказы \nчерез своё приложение",
					tabletTitle: "Принимай заказы \nчерез своё приложение",
					mobileTitle: "Принимай \nзаказы через \nсвоё \nприложение",
					desktopContent:
						"Запусти брендированное приложение — это быстро, без найма разработчиков. Гости смогут пользоваться бонусной программой, делать заказы на доставку.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Запусти собственный\nсайт с доставкой",
					tabletTitle: "Запусти собственный\nсайт с доставкой",
					mobileTitle: "Запусти \nсобственный\nсайт \nс доставкой",
					desktopContent:
						"Получай больше заказов с помощью сайта своего заведения. Подключение доставки с расчетом стоимости по зонам, опция самовывоза, всегда актуальное меню и различные способы оплаты.",
					tabletContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по зонам,&nbsp;опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по зонам,&nbsp;опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қосымша",
					desktopTitle: "Тапсырыстарды өз\nқосымшаңыз арқылы қабылдаңыз",
					tabletTitle: "Тапсырыстарды өз қосымшаңыз арқылы қабылдаңыз",
					mobileTitle: "Тапсырыстарды\nөз қосымшаңыз\nарқылы қабылдаңыз",
					desktopContent:
						"Брендтелген қосымшаны іске қосыңыз — бұл тез, әзірлеушілерді жалдаусыз болады. Қонақтар бонустық бағдарламаны пайдаланып, жеткізуге тапсырыстарды жасай алады.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Жеткізу мүмкіндігі\nбар сайтыңызды іске қосыңыз",
					tabletTitle: "Жеткізу мүмкіндігі бар\nсайтыңызды іске қосыңыз",
					mobileTitle: "Жеткізу\nмүмкіндігі бар\nсайтыңызды\nіске қосыңыз",
					desktopContent:
						"Мекемеңіздің сайты көмегімен көбірек тапсырыстар алыңыз. Аймақтар бойынша құнды есептеумен жеткізуді қосу, өзімен әкету опциясы, әрқашан өзекті мәзір және әртүрлі төлеу тәсілдері.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: "Больше заказов \nс приложением для гостей",
					tabletTitle: "Больше заказов \nс приложением для гостей",
					mobileTitle: "Больше заказов \nс приложением \nдля гостей",
					desktopContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. <br />
							Клиенты смогут пользоваться бонусной программой и делать заказы&nbsp;на&nbsp;доставку.
						</>
					),
					tabletContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной
							программой и&nbsp;делать заказы на&nbsp;доставку.
						</>
					),
					mobileContent: (
						<>
							Быстро, в&nbsp;фирменном стиле <br />
							и&nbsp;без найма разработчиков. <br />
							Клиенты смогут пользоваться <br />
							бонусной программой и&nbsp;делать <br />
							заказы на&nbsp;доставку.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_by.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "А ещё больше — \nчерез собственный сайт",
					tabletTitle: "А ещё больше — \nчерез собственный сайт",
					mobileTitle: "А ещё больше — \nчерез \nсобственный \nсайт",
					desktopContent: (
						<>
							Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза,&nbsp;всегда актуальное
							меню
							и&nbsp;разные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Подключение доставки с <br />
							расчётом стоимости по&nbsp;зонам, <br />
							опция самовывоза, всегда <br />
							актуальное меню и&nbsp;разные <br />
							способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_by.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Приложение",
				desktopTitle: (
					<>
						Больше заказов
						<br />с приложением для гостей
					</>
				),
				tabletTitle: "Больше заказов с приложением для гостей",
				mobileTitle: "Больше заказов с приложением для гостей",
				desktopContent: (
					<div>
						<p>
							Электронное QR-меню для ресторана с&nbsp;оформлением заказов на&nbsp;доставку и&nbsp;самовывоз.
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков.
						</p>
						<p>
							Гость любит заведение, но не успевает забежать даже на полчаса? Предложи заказать заранее кофе навынос, а
							затем забрать готовый напиток. Клиенты могут оформить заказ в приложении и сразу оплатить его картой
							онлайн. Удобно, если у курьеров заведения нет переносных терминалов для эквайринга.
						</p>
					</div>
				),
				tabletContent: (
					<div>
						<p>
							Электронное QR-меню для ресторана с&nbsp;оформлением заказов на&nbsp;доставку и&nbsp;самовывоз.
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков.
						</p>
						<p>
							Гость любит заведение, но не успевает забежать даже на полчаса? Предложи заказать заранее кофе навынос, а
							затем забрать готовый напиток. Клиенты могут оформить заказ в приложении и сразу оплатить его картой
							онлайн. Удобно, если у курьеров заведения нет переносных терминалов для эквайринга.
						</p>
					</div>
				),
				mobileContent: (
					<div>
						<p>
							Электронное QR-меню для ресторана с&nbsp;оформлением заказов на&nbsp;доставку и&nbsp;самовывоз.
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков.
						</p>
						<p>
							Гость любит заведение, но не успевает забежать даже на полчаса? Предложи заказать заранее кофе навынос, а
							затем забрать готовый напиток. Клиенты могут оформить заказ в приложении и сразу оплатить его картой
							онлайн. Удобно, если у курьеров заведения нет переносных терминалов для эквайринга.
						</p>
					</div>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container1}>
						<StaticImage
							className={classNames(mediaContent.image1, mediaContent.image1_ru)}
							src="../../../assets/images/mobileFeatures/phone.png"
							alt={"мобильное приложение для гостей"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<MobileAppBackdropIcon
						className={backdropIconsStyles.mobileAppBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Сайт",
				desktopTitle: (
					<>
						А ещё больше —<br />
						на полноценном сайте
					</>
				),
				tabletTitle: "А ещё больше — на полноценном сайте",
				mobileTitle: "А ещё больше — на полноценном сайте",
				tabContainerClass: mediaContent.container_ru,
				desktopContent: (
					<>
					<p>
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальные
						позиции меню и&nbsp;разные способы оплаты. Гости смогут посмотреть описание блюд, выбрать приборы
						и&nbsp;списать бонусы. История заказов сохраняется в&nbsp;личном кабинете.
					</p>
					<p>
						На&nbsp;сайте&nbsp;&mdash; полноценное продающее меню заведения: фото, КБЖУ, соусы и&nbsp;топпинги, действующие акции. Запускай сайт и&nbsp;принимай заказы на&nbsp;доставку и&nbsp;самовывоз.
						Сайт интегрирован с&nbsp;другими продуктами Quick Resto, адаптирован под компьютеры и&nbsp;смартфоны.
					</p>
					</>
				),
				tabletContent: (
					<p>
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню и&nbsp;разные способы оплаты.
					</p>
				),
				mobileContent: (
					<p>
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню и&nbsp;разные способы оплаты.
					</p>
				),
				detailLink: pagesLinks.indexWL,
				additionalDetailLink: externalLinks.demoWlsite,
				mediaContent: (
					<div className={mediaContent.image__container2}>
						<StaticImage
							className={classNames(mediaContent.image2, mediaContent.image2__ru)}
							src="../../../assets/images/mobileFeatures/shop-feature.webp"
							alt={"сайт с доставкой для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<WebsiteBackdropIcon
						className={backdropIconsStyles.websiteBackdropIcon}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};
