import * as React from "react"
import { SVGProps } from "react"

const OnlineIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.9957 15.4411L10.0059 6.49682L17.1552 11.8715L12.683 11.8664L9.9957 15.4411Z"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7 4.5H3.5C2.67157 4.5 2 5.17157 2 6V17C2 17.8284 2.67157 18.5 3.5 18.5H12H17H20.5C21.3284 18.5 22 17.8284 22 17V6C22 5.17157 21.3284 4.5 20.5 4.5H14"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M8 22.5H16"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12 18.5V22.5"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default OnlineIcon
