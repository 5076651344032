import React from "react"
import classNames from "classnames"

import FeaturesGallery from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"
import secondStyles from "../../index/MobileFeatures/styles.module.scss"
import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export function MobileFeaturesOther(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<section className={classNames(secondStyles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				className={secondStyles.featureGallery}
				leftColumnClass={secondStyles.leftColumn}
				theme={"green"}
				features={getData(localizationContext.locale).features}
			/>
		</section>
	)
}

export default function MobileFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				key={localizationContext.locale}
				className={styles.featureGallery}
				leftColumnClass={styles.leftColumn}
				theme={"green"}
				features={getData(localizationContext.locale).features}
			/>
		</section>
	)
}
