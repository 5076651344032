import React from "react"

import TabsFeatures from "../../../components/_V2/TabsFeatures"

import { HIDE_BADGES } from "../../../components/_V2/TabsFeatures"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";
import cn from "classnames";

interface Props {
	className?: string
}

export default function FormatFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<TabsFeatures
			key={localizationContext.locale}
			data={getData(localizationContext.locale)}
			className={props.className}
			rootClass={cn(styles.formatFeatures, {[styles.root_kz]: localizationContext.locale === 'ru-KZ'})}
			containerClass={styles.formatFeatures__container}
			wrapperClass={styles.formatFeatures__wrapper}
			additionalFeaturesListClass={styles.formatFeatures__list}
			isTabsInColumns
			theme={"purple-light"}
			isShowDescription
		/>
	)
}
