import { Locales } from "../../../localization/types";
import Presentation from "../ProductPresentation/assets/emenu-presentation.png";
import PresentationKz from "../ProductPresentation/assets/emenu-presentation_kz.webp";
import PresentationBy from "../ProductPresentation/assets/emenu-presentation_by.webp";
import {externalLinks} from "../../../pages-data/_V2/common/links";

export const getEmenuMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Электронное бесконтактное меню по QR коду для кафе и ресторанов — купить от Quick Resto Казахстан',
			metaTags: [
				{
					name: "description",
					content:
						"Электронное меню для кафе и ресторанов за 1 день ✔ Безопасно и гигиенично ✔ Доступно гостям со смартфона по QR-коду → Сэкономьте на бумажной версии меню и промо-буклетах. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}emenu/`,
				},
				{
					property: "og:title",
					content:
						"Электронное бесконтактное меню по QR коду для кафе и ресторанов — купить от Quick Resto Казахстан",
				},
				{
					property: "og:description",
					content:
						"Электронное меню для кафе и ресторанов за 1 день ✔ Безопасно и гигиенично ✔ Доступно гостям со смартфона по QR-коду → Сэкономьте на бумажной версии меню и промо-буклетах. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}emenu/`,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Электронное бесконтактное меню по QR коду для кафе и ресторанов — купить от Quick Resto в Беларуси',
			metaTags: [
				{
					name: "description",
					content:
						"Электронное меню для кафе и ресторанов за 1 день ✔ Безопасно и гигиенично ✔ Доступно посетителям со смартфона по QR-коду → Сэкономьте на бумажной версии меню и промо-буклетах. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}emenu/`,
				},
				{
					property: "og:title",
					content:
						"Электронное бесконтактное меню по QR коду для кафе и ресторанов — купить от Quick Resto в Беларуси",
				},
				{
					property: "og:description",
					content:
						"Электронное меню для кафе и ресторанов за 1 день ✔ Безопасно и гигиенично ✔ Доступно посетителям со смартфона по QR-коду → Сэкономьте на бумажной версии меню и промо-буклетах. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${PresentationBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}emenu/`,
		}
	}

	return {
		title: 'Электронное меню и QR-коды для кафе и ресторанов | Quick Resto',
		metaTags: [
			{
				name: "description",
				content:
					"Создайте электронное меню для кафе и ресторанов. QR меню для удобства гостей. Бесконтактное меню по QR коду. Интеграция с POS-системой. Купите онлайн меню от Quick Resto сейчас и увеличьте выручку!",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}emenu/`,
			},
			{
				property: "og:title",
				content:
					"Электронное меню и QR-коды для кафе и ресторанов | Quick Resto",
			},
			{
				property: "og:description",
				content:
					"Создайте электронное меню для кафе и ресторанов. QR меню для удобства гостей. Бесконтактное меню по QR коду. Интеграция с POS-системой. Купите онлайн меню от Quick Resto сейчас и увеличьте выручку!",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${Presentation}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}emenu/`,
	}
}
