import React from "react";
import { SolidTabsProps } from "../../../components/_V2/SolidTabs";
import styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales): SolidTabsProps => {
	if (locale === "ru-KZ") {
		return {
			items: [
				{
					tabName: "Брендинг",
					desktopTitle: "Всё по красоте",
					desktopContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики: <br />
							добавь цвета своего бренда, логотип и&nbsp;фотостиль.
						</p>
					),
					tabletContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
							добавь цвета своего бренда, логотип и&nbsp;фотостиль.
						</p>
					),
					mobileContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
							добавь цвета своего бренда, логотип&nbsp;и&nbsp;фотостиль.
						</p>
					),
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/emenu-content-branding-1_kz.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/emenu-content-branding-2_kz.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Маркетинг",
					desktopTitle: "Больше продаж",
					desktopContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай <br />
							контент-маркетинг, проводи розыгрыши и&nbsp;повышай лояльность.
						</>
					),
					tabletContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай
							контент-маркетинг, проводи розыгрыши и повышай&nbsp;лояльность.
						</>
					),
					mobileContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай
							контент-маркетинг, проводи розыгрыши и&nbsp;повышай лояльность.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image, styles.feature2Media__imageKz, styles.feature2Media__imageDesktop)}
								src={"./assets/emenu-content-marketing_kz.png"}
								alt={"программа лояльности для кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>

							<StaticImage
								className={classNames(styles.feature2Media__image, styles.feature2Media__imageMobile)}
								src={"./assets/emenu-content-marketing-mobile_kz.webp"}
								alt={"программа лояльности для кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			items: [
				{
					tabName: "Брендинг",
					desktopTitle: "Бәрі ойдағыдай",
					desktopContent: (
						<p>
							Онлайн-мәзір жалпы айдентикадан шықпайды: <br />
							брендіңіздің түстерін, логотипті және фотостильді қосыңыз.
						</p>
					),
					tabletContent: (
						<p>
							Онлайн-мәзір жалпы айдентикадан шықпайды:
							брендіңіздің түстерін, логотипті және фотостильді қосыңыз.
						</p>
					),
					mobileContent: (
						<p>
							Онлайн-мәзір жалпы айдентикадан шықпайды:
							брендіңіздің түстерін, логотипті және фотостильді қосыңыз.
						</p>
					),
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/emenu-content-branding-1_kz.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/emenu-content-branding-2_kz.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Маркетинг",
					desktopTitle: "Сату көбірек болсын",
					desktopContent: (
						<>
							Қонақтарды онлайн-мәзірден әлеуметтік желілерге шақырыңыз, және сонда <br />
							контент-маркетингті қосыңыз, ұтыс ойындарын өткізіңіз және адалдықты арттырыңыз.
						</>
					),
					tabletContent: (
						<>
							Қонақтарды онлайн-мәзірден әлеуметтік желілерге шақырыңыз, және сонда
							контент-маркетингті қосыңыз, ұтыс ойындарын өткізіңіз және адалдықты арттырыңыз.
						</>
					),
					mobileContent: (
						<>
							Қонақтарды онлайн-мәзірден әлеуметтік желілерге шақырыңыз, және сонда
							контент-маркетингті қосыңыз, ұтыс ойындарын өткізіңіз және адалдықты арттырыңыз.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image, styles.feature2Media__imageKz, styles.feature2Media__imageDesktop)}
								src={"./assets/emenu-content-marketing_kz.png"}
								alt={"программа лояльности для кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>

							<StaticImage
								className={classNames(styles.feature2Media__image, styles.feature2Media__imageMobile)}
								src={"./assets/emenu-content-marketing-mobile_kz.webp"}
								alt={"программа лояльности для кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			items: [
				{
					tabName: "Брендинг",
					desktopTitle: "Раскладывай по цветам",
					tabletTitle: "Раскладывай по цветам",
					mobileTitle: "Раскладывай \nпо цветам",
					desktopContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики: <br />
							добавь цвета своего бренда, логотип и&nbsp;фотостиль.
						</p>
					),
					tabletContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
							добавь цвета своего бренда, логотип и&nbsp;фотостиль.
						</p>
					),
					mobileContent: (
						<p>
							Онлайн-меню не&nbsp;выбьется из&nbsp;общей айдентики:
							добавь цвета своего бренда, логотип&nbsp;и&nbsp;фотостиль.
						</p>
					),
					mediaContent: (
						<div className={styles.feature1Media}>
							<StaticImage
								className={styles.feature1Media__image1}
								src="./assets/emenu-content-branding-1_by.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={styles.feature1Media__image2}
								src="./assets/emenu-content-branding-2_by.webp"
								alt={"онлайн меню кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
				{
					tabName: "Маркетинг",
					desktopTitle: "Больше продаж",
					mobileTitle: "Больше продаж",
					tabletTitle: "Больше продаж",
					desktopContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай <br />
							контент-маркетинг, проводи розыгрыши и&nbsp;повышай лояльность.
						</>
					),
					tabletContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай
							контент-маркетинг, проводи розыгрыши и повышай&nbsp;лояльность.
						</>
					),
					mobileContent: (
						<>
							Приглашай гостей из&nbsp;онлайн-меню в&nbsp;соцсети, а&nbsp;там подключай
							контент-маркетинг, проводи розыгрыши и&nbsp;повышай лояльность.
						</>
					),
					mediaContent: (
						<div className={styles.feature2Media}>
							<StaticImage
								className={classNames(styles.feature2Media__image)}
								src={"./assets/emenu-content-marketing_by.webp"}
								alt={"программа лояльности для кафе"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.featureBackground} />,
				},
			],
		};
	}

	return {
		items: [
			{
				tabName: "Брендинг",
				desktopTitle: "Всё по красоте",
				mobileTitle: "Всё по красоте",
				tabletTitle: "Всё по красоте",
				desktopContent: (
					<p>
						Онлайн-меню не выбьется из общей айдентики — добавь цвета своего бренда, логотип и фотостиль.
						Экспериментируй с оформлением и смотри на реакцию.
					</p>
				),
				tabletContent: (
					<p>
						Онлайн-меню не выбьется из общей айдентики — добавь цвета своего бренда, логотип и фотостиль.
						Экспериментируй с оформлением и смотри на реакцию.
					</p>
				),
				mobileContent: (
					<p>
						Онлайн-меню не выбьется из общей айдентики — добавь цвета своего бренда, логотип и фотостиль.
						Экспериментируй с оформлением и смотри на реакцию.
					</p>
				),
				mediaContent: (
					<div className={styles.feature1Media}>
						<StaticImage
							className={styles.feature1Media__image1}
							src="./assets/emenu-content-branding-1.png"
							alt={"онлайн меню кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={styles.feature1Media__image2}
							src="./assets/emenu-content-branding-2.png"
							alt={"онлайн меню кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
			{
				tabName: "Маркетинг",
				desktopTitle: "Больше продаж",
				mobileTitle: "Больше продаж",
				tabletTitle: "Больше продаж",
				desktopContent: (
					<>
						Меню, которое привлекает клиентов&nbsp;&mdash; приглашай гостей из&nbsp;онлайн-меню заведения
						в&nbsp;соцсети, а&nbsp;там подключай контент-маркетинг, проводи розыгрыши и&nbsp;повышай
						лояльность.
						< br/>
						< br/>
						Предлагай гостям доставку, и&nbsp;заведение больше не&nbsp;будет ограничено временем пиковых
						посещений и&nbsp;локацией.
					</>
				),
				tabletContent: (
					<>
						Меню, которое привлекает клиентов&nbsp;&mdash; приглашай гостей из&nbsp;онлайн-меню заведения
						в&nbsp;соцсети, а&nbsp;там подключай контент-маркетинг, проводи розыгрыши и&nbsp;повышай
						лояльность.
						< br/>
						< br/>
						Предлагай гостям доставку, и&nbsp;заведение больше не&nbsp;будет ограничено временем пиковых
						посещений и&nbsp;локацией.
					</>
				),
				mobileContent: (
					<>
						Меню, которое привлекает клиентов&nbsp;&mdash; приглашай гостей из&nbsp;онлайн-меню заведения
						в&nbsp;соцсети, а&nbsp;там подключай контент-маркетинг, проводи розыгрыши и&nbsp;повышай
						лояльность.
						< br/>
						< br/>
						Предлагай гостям доставку, и&nbsp;заведение больше не&nbsp;будет ограничено временем пиковых
						посещений и&nbsp;локацией.
					</>
				),
				mediaContent: (
					<div className={styles.feature2Media}>
						<StaticImage
							className={classNames(styles.feature2Media__image)}
							src={"./assets/emenu-content-marketing.png"}
							alt={"программа лояльности для кафе"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.featureBackground} />,
			},
		],
	};
};
