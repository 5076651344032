import { Locales } from "../../../localization/types";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

export const getAdvantagesSlides = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-1.png"
						alt="Быстро и&nbsp;экономно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Быстро и&nbsp;экономно",
				description:
					"Без затрат на&nbsp;разработчика и&nbsp;поддержку сайта, дизайнера и&nbsp;типографию.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-2.png"
						alt="Всегда актуально"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Всегда актуально",
				description:
					"Если хочешь изменить цену, добавить блюдо или акцию&nbsp;&mdash; просто обнови данные в&nbsp;бэк-офисе.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-3.png"
						alt="Удобно гостям"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Удобно гостям",
				description:
					"Посетитель открывает меню на&nbsp;смартфоне, зовёт официанта и&nbsp;делает заказ.",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-1.png"
						alt="Быстро и&nbsp;экономно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Тез және үнемді",
				description:
					"Әзірлеушіге және сайтты қолдауға, дизайнерге және типографияға шығынсыз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-2.png"
						alt="Всегда актуально"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Әрқашан өзекті",
				description:
					"Егер бағаны өзгерткіңіз, тағамды немесе акцияны қосқыңыз келсе - жай ғана бэк-офистегі деректерді жаңартыңыз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-3.png"
						alt="Удобно гостям"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Қонақтарға ыңғайлы",
				description:
					"Келуші смартфонда мәзірді ашады, даяшыны шақырады және тапсырыс жасайды.",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-1.png"
						alt="Экологично и экономично"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Экологично и экономично",
				description:
					"Без затрат на&nbsp;разработчика и&nbsp;поддержку сайта, дизайнера и&nbsp;типографию.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-2.png"
						alt="Всегда актуально"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Всегда актуально",
				description:
					"Если хочешь изменить цену, добавить блюдо или акцию&nbsp;&mdash; просто обнови данные в&nbsp;бэк-офисе.",
			},
			{
				img: (
					<StaticImage
						src="./assets/emenu-advantages-3.png"
						alt="Удобно гостям"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Удобно гостям",
				description:
					"Посетитель открывает меню на&nbsp;смартфоне, зовёт официанта и&nbsp;делает заказ.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/emenu-advantages-1.png"
					alt="Быстро и&nbsp;экономно"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Быстро и&nbsp;экономно",
			description:
				"Без затрат на&nbsp;разработчика и&nbsp;поддержку сайта, дизайнера и&nbsp;типографию.",
		},
		{
			img: (
				<StaticImage
					src="./assets/emenu-advantages-2.png"
					alt="Всегда актуально"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Всегда актуально",
			description:
				"Если хочешь изменить цену, добавить блюдо или акцию&nbsp;&mdash; просто обнови данные в&nbsp;бэк-офисе.",
		},
		{
			img: (
				<StaticImage
					src="./assets/emenu-advantages-3.png"
					alt="Удобно гостям"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Удобно гостям",
			description:
				"Посетитель сканирует QR-код, открывает меню на&nbsp;смартфоне, зовёт официанта и&nbsp;делает заказ.",
		},
	]
}
