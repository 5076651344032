import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getFeaturesData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				category: "удобство использования",
				title: "Легко и эстетично",
				mobileTitle: "Легко \nи эстетично",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.form__tabletDesktop}>
							Электронное меню полностью заменяет бумажную версию. Обновить цены или список блюд можно за&nbsp;считанные
							минуты: все позиции синхронизируются через бэк-офис. К&nbsp;тому&nbsp;же электронное меню
							не&nbsp;испортится от&nbsp;пролитых напитков и&nbsp;жирных пятен.
						</p>
						<noindex className={styles.form_mobile}>
							<p>
								Электронное меню полностью <br />
								заменяет бумажную версию. <br />
								Обновить цены или список блюд <br />
								можно за&nbsp;считанные минуты&nbsp;&mdash; <br />
								все позиции синхронизируются <br />
								через бэк-офис. К&nbsp;тому&nbsp;же, <br />
								электронное меню не&nbsp;испортится <br />
								от&nbsp;пролитых напитков <br />
								и&nbsp;жирных пятен.
							</p>
						</noindex>
					</div>
				),

				img: (
					<StaticImage
						className={styles.image}
						src="./assets/emenu-feature_kz.webp"
						alt="обновление электронного меню"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Оставить заявку",
				actionLink: "#popup",
			},
		];
	}

	if (locale === "kz-KZ") {
		return [
			{
				category: "пайдалану ыңғайлылығы",
				title: "Оңай және көрікті",
				mobileTitle: "Оңай\nжәне көрікті",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.form__tabletDesktop}>
							Электрондық мәзір қағаз нұсқасын толығымен алмастырады. Бағаларды немесе тағамдар тізімін санаулы минутта жаңартуға болады - барлық позициялар бэк-офис арқылы синхрондалады. Оған қоса, электрондық мәзір төгілген сусындардан және майлы дақтардан бүлінбейді.
						</p>
						<noindex className={styles.form_mobile}>
							<p>
								Электрондық мәзір қағаз нұсқасын толығымен алмастырады. Бағаларды немесе тағамдар тізімін санаулы минутта жаңартуға болады - барлық позициялар бэк-офис арқылы синхрондалады. Оған қоса, электрондық мәзір төгілген сусындардан және майлы дақтардан бүлінбейді.
							</p>
						</noindex>
					</div>
				),

				img: (
					<StaticImage
						className={styles.image}
						src="./assets/emenu-feature_kz.webp"
						alt="обновление электронного меню"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Өтінім қалдыру",
				actionLink: "#popup",
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				category: "удобство использования",
				title: "Просто и удобно",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.form__tabletDesktop}>
							Электронное меню полностью заменяет бумажную версию. Обновить цены или список блюд можно за&nbsp;считанные
							минуты: все позиции синхронизируются через бэк-офис. К&nbsp;тому&nbsp;же электронное меню
							не&nbsp;испортится от&nbsp;пролитых напитков и&nbsp;жирных пятен.
						</p>
						<noindex className={styles.form_mobile}>
							<p>
								Электронное меню полностью <br />
								заменяет бумажную версию. <br />
								Обновить цены или список блюд <br />
								можно за&nbsp;считанные минуты&nbsp;&mdash; <br />
								все позиции синхронизируются <br />
								через бэк-офис. К&nbsp;тому&nbsp;же, <br />
								электронное меню не&nbsp;испортится <br />
								от&nbsp;пролитых напитков <br />
								и&nbsp;жирных пятен.
							</p>
						</noindex>
					</div>
				),

				img: (
					<StaticImage
						className={styles.image}
						src="./assets/emenu-feature_by.webp"
						alt="обновление электронного меню"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature,
				theme: "cyan",
				actionText: "Оставить заявку",
				actionLink: "#popup",
			},
		];
	}

	return [
		{
			category: "удобство использования",
			title: "Легко и эстетично",
			description: (
				<div className={styles.smallDescription}>
					<p>
						Бесконтактное меню для ресторана за&nbsp;<span className={styles.noWrap}>за&nbsp;1&nbsp;490₽/мес.</span>
						Создадим и&nbsp;расскажем о&nbsp;выигрышных
						сценариях его использования. Полностью заменяет бумажную версию. К&nbsp;тому&nbsp;же
						не&nbsp;испортится от&nbsp;пролитых напитков и&nbsp;жирных пятен на&nbsp;столе. Меняй меню,
						когда захочешь, а&nbsp;не&nbsp;когда оно потеряло привлекательный вид.
					</p>
					<p>
						Изменить цены или добавить новые блюда можно за&nbsp;считаные минуты&nbsp;&mdash;
						все позиции меню синхронизируются через бэк-офис. Невозможно заказать гавайскую пиццу,
						когда в&nbsp;заведении закончились ананасы и&nbsp;блюдо попало в&nbsp;стоп-лист.
					</p>
				</div>
			),

			img: (
				<StaticImage
					className={styles.image}
					src="./assets/emenu-feature.png"
					alt="обновление электронного меню"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.feature,
			theme: "cyan",
			actionText: "Оставить заявку",
			actionLink: "#popup",
			additionalActionText: "Посмотреть демо QR-меню",
			additionalActionLink: "https://bd002.menuquickresto.ru/",
		},
	];
};
