import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./styles.module.scss";
import CoffeeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon";
import OnlineIcon from "../../../assets/_V2/svg/OnlineIcon";
import RestaurantIcon from "../../../assets/_V2/svg/RestaurantIcon";
import DeliveryIcon from "../../../assets/_V2/svg/DeliveryIcon";
import classNames from "classnames";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			title: "Используй \nвсе площадки",
			headerPlacement: "left",
			features: [
				{
					icon: <OnlineIcon />,
					name: "Онлайн",
					description:
						"Размести ссылку на&nbsp;электронное меню в&nbsp;соцсетях и&nbsp;на&nbsp;онлайн-картах города.",
					mediaContent: (
						<StaticImage
							className={classNames(styles.formatFeatures__image, styles.formatFeatures__imageKz)}
							src="./assets/emenu-format-1_kz.webp"
							alt="электронное меню по ссылке"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <RestaurantIcon />,
					name: "Ресторан и кафе",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__restDescription, styles.form__tabletDesktop)}>
								Гостям не&nbsp;нужно ждать официанта, чтобы получить меню. QR-код&nbsp;&mdash; на&nbsp;входе в&nbsp;кафе
								и&nbsp;промо-листовках.
							</div>
							<noindex className={classNames(styles.formatFeatures__restDescription, styles.form_mobile)}>
								<div>
									Гостям не&nbsp;нужно ждать официанта, чтобы получить меню.&nbsp;QR-код&nbsp;&mdash; на&nbsp;входе
									в&nbsp;кафе и&nbsp;промо-листовках.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullRest,
							)}
							src="./assets/emenu-format-2.png"
							alt="меню на qr-коде"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <CoffeeIcon />,
					name: "Кофейня",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__coffeeDescription, styles.form__tabletDesktop)}>
								В&nbsp;очереди на&nbsp;кассе посетители заранее выберут напитки и&nbsp;десерты.
							</div>
							<noindex className={classNames(styles.formatFeatures__coffeeDescription, styles.form_mobile)}>
								<div>
									В&nbsp;очереди на&nbsp;кассе посетители заранее выберут напитки и десерты.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullCoffee,
							)}
							src="./assets/emenu-format-3.png"
							alt="qr-меню для ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <DeliveryIcon />,
					name: "Доставка",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__deliveryDescription, styles.form__tabletDesktop)}>
								Для будущих заказов гостю можно отдать одну листовку с&nbsp;QR-кодом вместо объёмного буклета.
							</div>
							<noindex className={classNames(styles.formatFeatures__deliveryDescription, styles.form_mobile)}>
								<div>
									Для будущих заказов гостю можно&nbsp;отдать одну листовку <br />
									с QR-кодом вместо объёмного буклета.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullDelivery,
							)}
							src="./assets/emenu-format-4.png"
							alt="электронное меню для доставки"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			title: "Барлық алаңдарды\nпайдаланыңыз",
			headerPlacement: "left",
			features: [
				{
					icon: <OnlineIcon />,
					name: "Онлайн",
					description:
						"Электрондық мәзірге сілтемені әлеуметтік желілерде және қаланың онлайн-карталарында орналастырыңыз. ",
					mediaContent: (
						<StaticImage
							className={classNames(styles.formatFeatures__image, styles.formatFeatures__imageKz)}
							src="./assets/emenu-format-1_kz.webp"
							alt="электронное меню по ссылке"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <RestaurantIcon />,
					name: "Мейрамхана мен кафе",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__restDescription, styles.form__tabletDesktop)}>
								Мәзірді алу үшін қонақтарға даяшыны күтудің керегі жоқ.
								QR-код — кафеге кіреберісте және промо-парақшаларда.
							</div>
							<noindex className={classNames(styles.formatFeatures__restDescription, styles.form_mobile)}>
								<div>
									Мәзірді алу үшін қонақтарға даяшыны күтудің керегі жоқ.
									QR-код — кафеге кіреберісте және промо-парақшаларда.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullRest,
							)}
							src="./assets/emenu-format-2.png"
							alt="меню на qr-коде"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <CoffeeIcon />,
					name: "Кофехана",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__coffeeDescription, styles.form__tabletDesktop)}>
								Кассада кезекте тұрғанда келушілер
								сусындар мен десерттерді алдын ала таңдайды.
							</div>
							<noindex className={classNames(styles.formatFeatures__coffeeDescription, styles.form_mobile)}>
								<div>
									Кассада кезекте тұрғанда келушілер
									сусындар мен десерттерді алдын ала таңдайды..
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullCoffee,
							)}
							src="./assets/emenu-format-3.png"
							alt="qr-меню для ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <DeliveryIcon />,
					name: "Жеткізу",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__deliveryDescription, styles.form__tabletDesktop)}>
								Болашақ тапсырыстар үшін қонаққа көлемді буклеттің орнына QR-коды бар бір парақшаны берсеңіз болады.
							</div>
							<noindex className={classNames(styles.formatFeatures__deliveryDescription, styles.form_mobile)}>
								<div>
									Болашақ тапсырыстар үшін қонаққа көлемді буклеттің <br /> орнына QR-коды бар бір парақшаны берсеңіз болады.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullDelivery,
							)}
							src="./assets/emenu-format-4.png"
							alt="электронное меню для доставки"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			title: "Для любых форматов",
			headerPlacement: "left",
			features: [
				{
					icon: <OnlineIcon />,
					name: "Онлайн",
					description:
						"Размести ссылку на&nbsp;электронное меню в&nbsp;соцсетях и&nbsp;на&nbsp;онлайн-картах города.",
					mediaContent: (
						<StaticImage
							className={classNames(styles.formatFeatures__image, styles.formatFeatures__imageBy)}
							src="./assets/emenu-format-1_by.webp"
							alt="электронное меню по ссылке"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <RestaurantIcon />,
					name: "Ресторан и кафе",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__restDescription, styles.form__tabletDesktop)}>
								Гостям не&nbsp;нужно ждать официанта, чтобы получить меню. QR-код&nbsp;&mdash; на&nbsp;входе в&nbsp;кафе
								и&nbsp;промо-листовках.
							</div>
							<noindex className={classNames(styles.formatFeatures__restDescription, styles.form_mobile)}>
								<div>
									Гостям не&nbsp;нужно ждать официанта, чтобы получить меню.&nbsp;QR-код&nbsp;&mdash; на&nbsp;входе
									в&nbsp;кафе и&nbsp;промо-листовках.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullRest,
							)}
							src="./assets/emenu-format-2.png"
							alt="меню на qr-коде"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <CoffeeIcon />,
					name: "Кофейня",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__coffeeDescription, styles.form__tabletDesktop)}>
								В&nbsp;очереди на&nbsp;кассе посетители заранее выберут напитки и&nbsp;десерты.
							</div>
							<noindex className={classNames(styles.formatFeatures__coffeeDescription, styles.form_mobile)}>
								<div>
									В&nbsp;очереди на&nbsp;кассе посетители заранее выберут напитки и десерты.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullCoffee,
							)}
							src="./assets/emenu-format-3.png"
							alt="qr-меню для ресторана"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					icon: <DeliveryIcon />,
					name: "Доставка",
					description: (
						<>
							<div className={classNames(styles.formatFeatures__deliveryDescription, styles.form__tabletDesktop)}>
								Для будущих заказов гостю можно отдать одну листовку с&nbsp;QR-кодом вместо объёмного буклета.
							</div>
							<noindex className={classNames(styles.formatFeatures__deliveryDescription, styles.form_mobile)}>
								<div>
									Для будущих заказов гостю можно&nbsp;отдать одну листовку <br />
									с QR-кодом вместо объёмного буклета.
								</div>
							</noindex>
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(
								styles.formatFeatures__imageFull,
								styles.formatFeatures__imageFullDelivery,
							)}
							src="./assets/emenu-format-4.png"
							alt="электронное меню для доставки"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		};
	}

	return {
		title: "QR меню для любых форматов бизнеса",
		headerPlacement: "left",
		features: [
			{
				icon: <OnlineIcon />,
				name: "Онлайн",
				description:
					"Размести QR-код или ссылку на&nbsp;бесконтактное меню в&nbsp;соцсетях и&nbsp;на&nbsp;онлайн-картах города. Гости придут напрямую без агрегаторов. А&nbsp;значит, не&nbsp;придётся платить комиссию и&nbsp;конкурировать с&nbsp;десятками акций конкурентов.",
				mediaContent: (
					<StaticImage
						className={classNames(styles.formatFeatures__image, styles.formatFeatures__imageRu)}
						src="./assets/emenu-format-1.png"
						alt="электронное меню по ссылке"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				icon: <RestaurantIcon />,
				name: "Ресторан",
				description: (
					<div className={styles.formatFeatures__restDescription}>
						Чтобы получить меню ресторана, не&nbsp;нужен вызов официанта. Размести QR-код на&nbsp;входе
						в&nbsp;кафе и&nbsp;промолистовках, поставь таблички с&nbsp;кодами на&nbsp;столики.
					</div>
				),
				mediaContent: (
					<StaticImage
						className={classNames(
							styles.formatFeatures__imageFull,
							styles.formatFeatures__imageFullRest,
						)}
						src="./assets/emenu-format-2.png"
						alt="меню на qr-коде"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				icon: <CoffeeIcon />,
				name: "Кофейня",
				description: (
					<div className={styles.formatFeatures__coffeeDescription}>
						В&nbsp;очереди на&nbsp;кассе посетители заранее выберут напитки и&nbsp;десерты.
						Разгружай сотрудников, принимай заказы быстрее, обслуживай больше гостей. Не&nbsp;теряй прибыль
						в&nbsp;пиковые часы. Даже&nbsp;те, кто спешит, не&nbsp;уйдут голодными.
					</div>
				),
				mediaContent: (
					<StaticImage
						className={classNames(
							styles.formatFeatures__imageFull,
							styles.formatFeatures__imageFullCoffee,
						)}
						src="./assets/emenu-format-3.png"
						alt="qr-меню для ресторана"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				icon: <DeliveryIcon />,
				name: "Доставка",
				description: (
					<div className={styles.formatFeatures__deliveryDescription}>
						Для будущих заказов гостю можно отдать одну листовку с&nbsp;электронным меню по&nbsp;QR-коду
						вместо объёмного буклета. Гости уже привыкли заказывать со&nbsp;смартфона в&nbsp;пару кликов
						и&nbsp;не&nbsp;хотят звонить по&nbsp;телефону.
					</div>
				),
				mediaContent: (
					<StaticImage
						className={classNames(
							styles.formatFeatures__imageFull,
							styles.formatFeatures__imageFullDelivery,
						)}
						src="./assets/emenu-format-4.png"
						alt="электронное меню для доставки"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	};
};
