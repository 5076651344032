import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'
import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/emenu.module.scss'
import Advantages from '../components/_V2/Advantages'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import BusinessTypes from '../components/_V2/BusinessTypes'

import EmenuProductPresentation from '../pagesSections/emenu/ProductPresentation'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import MobileFeatures, { MobileFeaturesOther } from '../pagesSections/emenu/MobileFeatures'

import MainFeatures from '../pagesSections/emenu/MainFeatures'
import FormatFeatures from '../pagesSections/emenu/FormatFeatures'
import { getEmenuMeta } from '../pagesSections/emenu/meta'

import { getAdvantagesSlides } from '../pages-data/_V2/emenu/advantages'
import { getFeaturesData } from '../pages-data/_V2/emenu/features'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import { getSeoText } from "../pages-data/_V2/emenu/seotext";
import SeoText from "../components/_V2/SeoText";

export default function EmenuPage() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getEmenuMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <EmenuProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        className={classNames(styles.emenu__advantages, pageStyles.pageSection)}
        hideImages={false}
      />

      <MainFeatures className={pageStyles.pageSection} />

      <FormatFeatures className={pageStyles.pageSection} />

      <SimpleFeatures data={getFeaturesData(localizationContext.locale)} className={pageStyles.pageSection} />

      {localizationContext.locale === 'ru-RU' ? (
        <MobileFeatures className={pageStyles.pageSection} />
      ) : (
        <MobileFeaturesOther className={pageStyles.pageSection} />
      )}

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
