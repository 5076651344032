import * as React from "react"
import { SVGProps } from "react"

const RestaurantIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="21"
		height="21"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.8611 1.47217V6.56304C13.8611 8.25 15.2286 9.61756 16.9156 9.61756C18.6026 9.61756 19.9701 8.25 19.9701 6.56304V1.47217"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.9158 19.5271V12.7156"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.997 1.47241C6.01125 1.47241 1.96948 5.51418 1.96948 10.5C1.96948 15.4857 6.01125 19.5275 10.997 19.5275C12.0635 19.5275 13.0868 19.3426 14.0366 19.003"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M10.9969 5.08325C8.00539 5.08325 5.58032 7.50832 5.58032 10.4998C5.58032 13.4912 8.00539 15.9163 10.9969 15.9163C12.089 15.9163 13.1057 15.5931 13.9564 15.037"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M16.9158 6.30486V1.47217"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default RestaurantIcon
