import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "электронное меню",
			desktopTitle: "Больше не нужно \nждать меню",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Не&nbsp;нужно ждать, пока напечатают меню с&nbsp;сезонными блюдами. А&nbsp;с&nbsp;акциями можно
						экспериментировать хоть&nbsp;каждый день.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Не&nbsp;нужно ждать, пока напечатают меню с&nbsp;сезонными блюдами. А&nbsp;с&nbsp;акциями можно
							экспериментировать хоть каждый&nbsp;день.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/emenu-presentation_kz.webp"
					alt="электронное меню для ресторана"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "электрондық мәзірлер",
			desktopTitle: "Енді мәзірді\nкүтудің керегі жоқ",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Маусымдық тағамдар бар мәзірді басып шығаратынын күтудің керегі жоқ. Ал акциялармен күн сайын болса да тәжірибе жасауға болады.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Маусымдық тағамдар бар мәзірді басып шығаратынын күтудің керегі жоқ. Ал акциялармен күн сайын болса да тәжірибе жасауға болады.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinksKz.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/emenu-presentation_kz.webp"
					alt="электронное меню для ресторана"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	if (locale === "ru-BY") {
		return {
			pageName: "электронное меню",
			desktopTitle: "Меню больше \nне надо ждать",
			mobileTitle: "Меню больше \nне надо ждать",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Не&nbsp;нужно ждать, пока напечатают меню с&nbsp;сезонными блюдами. А&nbsp;с&nbsp;акциями можно
						экспериментировать хоть&nbsp;каждый день.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Не&nbsp;нужно ждать, пока напечатают меню с&nbsp;сезонными блюдами. А&nbsp;с&nbsp;акциями можно
							экспериментировать хоть каждый&nbsp;день.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					src="./assets/emenu-presentation_by.webp"
					alt="электронное меню для ресторана"
					className={styles.image}
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		};
	}

	return {
		pageName: "электронное меню",
		desktopTitle: "Без лишних прикосновений",
		subTitle: (
			<>
				Экономь на&nbsp;бумажной версии меню и&nbsp;промобуклетах&nbsp;&mdash; нужна лишь ссылка на&nbsp;сайт.
				Не&nbsp;нужно ждать, пока напечатают меню с&nbsp;сезонными блюдами. А&nbsp;с&nbsp;акциями можно экспериментировать
				хоть каждый день.
			</>
		),
		cta: pagesLinks.registration.text,
		// desktopCtaDetails:
		// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				src="./assets/emenu-presentation.png"
				alt="электронное меню для ресторана"
				className={styles.image}
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	};
};
